import { Box, Container, Heading, Text, Flex } from '@chakra-ui/react';
import { SanityTextWithIllustration } from '../../../graphql-types';
import useLocale from '../../lib/useLocale';
import BlockContent from '../BlockContent';
import ImageWithCropAndHotspot from '../ImageWithCropAndHotspot';

interface TextWithIllustrationProps {
    data: SanityTextWithIllustration;
}

function getFlexDirection(direction: string) {
    switch (direction) {
        case 'toBottom':
            return 'column-reverse';
        case 'toTop':
            return 'column';
        case 'toLeft':
            return 'row';
        case 'toRight':
            return 'row-reverse';
        default:
            return 'row';
    }
}

function getOnlyMobileDirections(direction: string) {
    if (direction === 'toLeft' || direction === 'toRight') {
        return 'toTop';
    }
    return direction;
}

export default function TextWithIllustration({
    data,
}: TextWithIllustrationProps) {
    const { translate } = useLocale();
    const title = data?.title ? translate(data?.title) : '';
    const { description, illustration, direction } = data;

    const renderImage = () => {
        if (!illustration) return null;
        const caption = illustration.image?.caption
            ? translate(illustration.image?.caption)
            : '';

        return (
            <Box flex="1">
                <Box maxH="80vh">
                    <ImageWithCropAndHotspot
                        image={illustration?.image?.asset?.gatsbyImageData}
                        alt={caption || title}
                        hotspot={illustration?.image?.hotspot}
                        crop={illustration?.image?.crop}
                        imgStyle={{
                            maxHeight: '80vh',
                            width: 'auto',
                        }}
                    />
                </Box>

                {!!caption && (
                    <Text mt={4} color="gray.600" textAlign="center">
                        {caption}
                    </Text>
                )}
            </Box>
        );
    };

    return (
        <Box>
            <Container maxW="container.lg">
                <Flex
                    direction={{
                        base: getFlexDirection(
                            getOnlyMobileDirections(direction || 'toTop')
                        ),
                        md: getFlexDirection(direction || 'toLeft'),
                    }}
                    alignItems="center"
                    gap={20}
                >
                    {renderImage()}
                    <Box flex="1">
                        {!!title && (
                            <Heading mb={8} size="2xl" color="primary.600">
                                {title}
                            </Heading>
                        )}
                        {!!description && <BlockContent data={description} />}
                    </Box>
                </Flex>
            </Container>
        </Box>
    );
}
