import {
    Heading,
    Box,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';
import { SanityFaq } from '../../../graphql-types';
import useLocale from '../../lib/useLocale';
import BlockContent from '../BlockContent';

interface FAQProps {
    data: SanityFaq;
}

export default function FAQ({ data }: FAQProps) {
    const { translate } = useLocale();
    const title = data?.title ? translate(data?.title) : '';

    return (
        <AccordionItem>
            <h2>
                <AccordionButton py={8}>
                    <Box flex="1" textAlign="left">
                        <Heading as="h4" size="md" fontWeight="bold">
                            {title}
                        </Heading>
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={12} color="gray.600">
                {!!data.description && <BlockContent data={data.description} />}
            </AccordionPanel>
        </AccordionItem>
    );
}
