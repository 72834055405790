import { graphql, PageProps } from 'gatsby';
import SEO from '../components/SEO';
import { SanityPage } from '../../graphql-types';
import Content from '../components/Content';
import useLocale from '../lib/useLocale';
import PageHeader from '../components/PageHeader';

interface PrivacyPolicyPageProps extends PageProps {
    data: {
        page: SanityPage;
    };
}

export default function PrivacyPolicyPage({ data }: PrivacyPolicyPageProps) {
    const { translate } = useLocale();
    return (
        <div>
            <SEO
                title={
                    data.page.title
                        ? translate(data.page.title)
                        : 'Política de privacidad'
                }
            />
            {data.page.title && <PageHeader title={data.page.title} />}
            <Content data={data.page.content} />
        </div>
    );
}

export const query = graphql`
    query PrivacyPolicyPageQuery($language: String!) {
        page: sanityPage(slug: { current: { eq: "politica-de-privacidad" } }) {
            ...SanityPageFragment
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            ...LocaleFragment
        }
    }
`;
