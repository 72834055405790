import { Box, Heading, Text } from '@chakra-ui/react';
import { Link } from 'gatsby-plugin-react-i18next';
import { SanityCategory } from '../../graphql-types';
import useLocale from '../lib/useLocale';
import ImageWithCropAndHotspot from './ImageWithCropAndHotspot';

interface CategoryCardProps {
    category: SanityCategory;
    variant?: number;
}

export default function CategoryCard({
    category,
    variant = 1,
}: CategoryCardProps) {
    const { translate } = useLocale();
    const title = category?.title ? translate(category?.title) : '';
    const description = category?.description
        ? translate(category?.description)
        : '';

    if (variant === 1) {
        return (
            <Box
                as={Link}
                to={`/catalogo/${category?.slug?.current}`}
                display="grid"
                rounded="lg"
                overflow="hidden"
                willChange="transform"
                h="full"
                w="full"
                _hover={{
                    boxShadow: '2xl',
                    cursor: 'pointer',
                }}
                transition=".3s ease-in-out"
            >
                {category?.image?.asset?.gatsbyImageData && (
                    <ImageWithCropAndHotspot
                        image={category?.image?.asset?.gatsbyImageData}
                        crop={category?.image?.crop}
                        hotspot={category?.image?.hotspot}
                        style={{
                            gridArea: '1/1',
                        }}
                        alt={title}
                    />
                )}
                <Box
                    gridArea="1/1"
                    position="relative"
                    placeItems="end center"
                    p={4}
                    pb={6}
                    display="grid"
                    bgGradient="linear(to-t, rgba(0, 0, 0, 0.3), transparent)"
                    _hover={{
                        bgGradient:
                            'linear(to-r, rgba(0, 0, 0, 0.3), transparent)',
                    }}
                >
                    <Heading fontSize="2xl" fontWeight={600} color="white">
                        {title}
                    </Heading>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            as={Link}
            to={`/catalogo/${category?.slug?.current}`}
            h="full"
            w="full"
        >
            {category?.image?.asset?.gatsbyImageData && (
                <Box rounded="lg" overflow="hidden">
                    <ImageWithCropAndHotspot
                        image={{
                            ...category?.image?.asset?.gatsbyImageData,
                            height: 5568,
                            width: 3712,
                            // layout: 'fullWidth',
                        }}
                        crop={category?.image?.crop}
                        hotspot={category?.image?.hotspot}
                        alt={title}
                    />
                </Box>
            )}
            <Heading fontSize="xl" mt={4}>
                {title}
            </Heading>
            <Text mt={4} color="gray.600" noOfLines={3}>
                {description}
            </Text>
        </Box>
    );
}
