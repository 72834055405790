import { Container, VStack } from '@chakra-ui/react';
import {
    Maybe,
    SanityCategoriesList,
    SanityCategoriesListOrCtaOrFaqOrHeroOrLocaleBlockContentOrProductsListOrTextWithIllustration,
    SanityCta,
    SanityFaq,
    SanityHero,
    SanityLocaleBlockContent,
    SanityProductsList,
    SanityTextWithIllustration,
} from '../../../graphql-types';
import BlockContent from '../BlockContent';
import CategoriesList from './CategoriesList';
import CTA from './CTA';
import FAQ from './FAQ';
import Hero from './Hero';
import ProductsList from './ProductsList';
import TextWithIllustration from './TextWithIllustration';

interface ContentProps {
    data:
        | Maybe<
              Array<
                  Maybe<SanityCategoriesListOrCtaOrFaqOrHeroOrLocaleBlockContentOrProductsListOrTextWithIllustration>
              >
          >
        | undefined;
    spacing?: number;
}

export default function Content({ data, spacing = 32 }: ContentProps) {
    if (!data) return null;
    return (
        <VStack spacing={spacing} align="stretch">
            {data
                .filter(item => !!item?._key && item?._type)
                .map(item => {
                    switch (item?._type) {
                        case 'hero':
                            return (
                                <Hero
                                    data={item as SanityHero}
                                    key={item._key}
                                />
                            );
                        case 'cta':
                            return (
                                <CTA data={item as SanityCta} key={item._key} />
                            );
                        case 'categoriesList':
                            return (
                                <CategoriesList
                                    data={item as SanityCategoriesList}
                                    key={item._key}
                                />
                            );
                        case 'productsList':
                            return (
                                <ProductsList
                                    data={item as SanityProductsList}
                                    key={item._key}
                                />
                            );
                        case 'localeBlockContent':
                            return (
                                <Container
                                    maxW="container.lg"
                                    pb={12}
                                    key={item._key}
                                >
                                    <BlockContent
                                        data={item as SanityLocaleBlockContent}
                                    />
                                </Container>
                            );
                        // Faq returns an AccordionItem that must be wrapped in an Accordion
                        case 'faq':
                            return (
                                <FAQ key={item._key} data={item as SanityFaq} />
                            );
                        case 'textWithIllustration':
                            return (
                                <TextWithIllustration
                                    key={item._key}
                                    data={item as SanityTextWithIllustration}
                                />
                            );
                        default:
                            return null;
                    }
                })}
        </VStack>
    );
}
