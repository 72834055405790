import { Heading, Container, Box, Button, Link, Stack } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import { SanityHero } from '../../../graphql-types';
import useLocale from '../../lib/useLocale';
import BlockContent from '../BlockContent';
import ImageWithCropAndHotspot from '../ImageWithCropAndHotspot';

interface HeroProps {
    data: SanityHero;
}

export default function Hero({ data }: HeroProps) {
    const { translate } = useLocale();
    const title = data?.title ? translate(data?.title) : '';
    return (
        <Box height="75vh" display="grid">
            {data?.image?.asset?.gatsbyImageData && (
                <ImageWithCropAndHotspot
                    image={data?.image?.asset?.gatsbyImageData}
                    crop={data?.image?.crop}
                    hotspot={data?.image?.hotspot}
                    style={{
                        gridArea: '1/1',
                    }}
                    alt={title}
                />
            )}
            <Container
                size="container.lg"
                gridArea="1/1"
                maxW="5xl"
                position="relative"
            >
                <Stack
                    spacing={4}
                    textAlign="center"
                    color="white"
                    justify="center"
                    align="center"
                    h="full"
                >
                    <Heading
                        fontWeight="bold"
                        size="4xl"
                        fontSize={{
                            base: '3xl',
                            sm: '4xl',
                            md: '6xl',
                            lg: '7xl',
                        }}
                    >
                        {title}
                    </Heading>
                    {!!data.description && (
                        <Box fontSize={{ base: 'lg', sm: 'xl', md: '2xl' }}>
                            <BlockContent data={data.description} />
                        </Box>
                    )}
                    {data.link && (
                        <Box>
                            <Button
                                my={2}
                                color="primary.900"
                                bg="white"
                                as={data?.link?.route ? GatsbyLink : Link}
                                to={
                                    data?.link?.route
                                        ? data?.link?.route
                                        : data.link.link
                                }
                                size="lg"
                                minW={180}
                                py={7}
                            >
                                {translate(data.link?.title || {})}
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Container>
        </Box>
    );
}
