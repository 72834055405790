import { Center, Heading } from '@chakra-ui/react';
import { SanityLocaleString } from '../../graphql-types';
import useLocale from '../lib/useLocale';

interface PageHeaderProps {
    title: SanityLocaleString;
    children?: React.ReactNode;
}

export default function PageHeader({ title, children }: PageHeaderProps) {
    const { translate } = useLocale();
    return (
        <Center py={32} flexDirection="column">
            <Heading
                // fontWeight="bold"
                size="4xl"
                fontSize={{
                    base: '3xl',
                    sm: '4xl',
                    md: '5xl',
                    lg: '6xl',
                }}
            >
                {translate(title)}
            </Heading>
            {children}
        </Center>
    );
}
