import {
    Heading,
    Container,
    Box,
    Button,
    Text,
    SimpleGrid,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { SanityCategoriesList } from '../../../graphql-types';
import useLocale from '../../lib/useLocale';
import CategoryCard from '../CategoryCard';

export const CATEGORIES_QUERY = graphql`
    query getAllCategories {
        categories: allSanityCategory(
            filter: { image: { asset: { _id: { ne: null } } } }
        ) {
            nodes {
                ...SanityCategoryFragment
            }
        }
    }
`;

interface CategoriesListProps {
    data: SanityCategoriesList;
}
export default function CategoriesList({ data }: CategoriesListProps) {
    const { translate } = useLocale();
    const title = data?.title ? translate(data?.title) : '';
    const description = data?.description ? translate(data?.description) : '';
    const { categories } = useStaticQuery(CATEGORIES_QUERY);
    const filteredCategories = useMemo(
        () =>
            categories.nodes
                .filter(cat => {
                    if (!data.category) return true;
                    return cat.parents?.some(
                        p => p?._id === data.category?._id
                    );
                })
                .slice(0, data.variant === 1 ? 4 : 3),
        [categories, data]
    );
    return (
        <Box>
            <Container maxW="container.lg">
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <Heading>{title}</Heading>
                    {!!data.linkText && (
                        <Button
                            colorScheme="primary"
                            color="primary.600"
                            as={GatsbyLink}
                            to={`/catalogo/${
                                data?.category?.slug?.current || 'categorias'
                            }`}
                            variant="link"
                        >
                            {translate(data.linkText)}
                        </Button>
                    )}
                </Box>
                {!!description && (
                    <Text mt={4} color="gray.600">
                        {description}
                    </Text>
                )}
                <SimpleGrid
                    // columns={data.variant === 1 ? 5 : 3}
                    minChildWidth="200px"
                    spacing={10}
                    pt={6}
                >
                    {filteredCategories.map(item => (
                        <CategoryCard
                            key={item.id}
                            category={item}
                            variant={data.variant || 1}
                        />
                    ))}
                </SimpleGrid>
            </Container>
        </Box>
    );
}
