import { Heading, Container, Box, Button, Text, Grid } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby-plugin-react-i18next';
import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { SanityProduct, SanityProductsList } from '../../../graphql-types';
import useLocale from '../../lib/useLocale';
import ProductCard from '../ProductCard';

export const PRODUCTS_QUERY = graphql`
    query getAllProducts {
        products: allSanityProduct(filter: { available: { eq: true } }) {
            nodes {
                ...SanityProductFragment
                categories {
                    id
                }
            }
        }
        categories: allSanityCategory(
            filter: { image: { asset: { _id: { ne: null } } } }
        ) {
            nodes {
                ...SanityCategoryFragment
            }
        }
    }
`;

interface ProductsListProps {
    data: SanityProductsList;
}
export default function ProductsList({ data }: ProductsListProps) {
    const { translate } = useLocale();
    const title = data?.title ? translate(data?.title) : '';
    const description = data?.description ? translate(data?.description) : '';
    const { products } = useStaticQuery(PRODUCTS_QUERY);

    const filteredProducts: SanityProduct[] = useMemo(() => {
        let result = products.nodes;
        if (data.category)
            result = products.nodes.filter(p =>
                p.categories.some(c => c.id === data.category?.id)
            );
        if (data.filter) result = result.filter(p => p.state === data.filter);
        return result.slice(0, 4);
    }, [data, products]);

    return (
        <Box>
            <Container maxW="container.lg">
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <Heading>{title}</Heading>
                    {!!data.linkText && (
                        <Button
                            colorScheme="primary"
                            color="primary.600"
                            as={GatsbyLink}
                            to={`/catalogo/${
                                data?.category?.slug?.current || 'categorias'
                            }`}
                            variant="link"
                        >
                            {translate(data.linkText)}
                        </Button>
                    )}
                </Box>
                {!!description && (
                    <Text mt={4} color="gray.600">
                        {description}
                    </Text>
                )}
                <Grid
                    templateColumns="repeat(auto-fill, minmax(18rem, 1fr))"
                    gap={10}
                    pt={6}
                >
                    {filteredProducts.map(item => (
                        <ProductCard key={item.id} product={item} />
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}
